var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mb-4",on:{"mouseover":_vm.setAnimationTrue,"mouseleave":_vm.setAnimationFalse}},[_c('button',{staticClass:"text-slate-600 dark:text-slate-100 w-10 h-10 my-2 flex items-center justify-center rounded-lg hover:bg-slate-25 dark:hover:bg-slate-700 dark:hover:text-slate-100 hover:text-slate-600 relative",class:{
      'bg-woot-50 dark:bg-slate-800 text-woot-500 hover:bg-woot-50':
        _vm.isNotificationPanelActive,
    },on:{"click":_vm.openNotificationPanel}},[(!_vm.animation)?_c('fluent-icon',{attrs:{"viewBox":'0 0 31 31',"icon":_vm.isNotificationPanelOpen ? 'alert-clicked' : 'alert',"size":25}}):_vm._e(),_vm._v(" "),_c('span',{class:_vm.animation ? '' : 'hidden'},[_c('lottie-vue-player',{style:({
          width: '25px',
          height: '25px',
          background: 'transparent',
        }),attrs:{"src":`/assets/animations/alert.json`,"show-color-picker":false,"loop":true,"autoplay":true,"player-controls":false}})],1),_vm._v(" "),(_vm.unreadCount)?_c('span',{staticClass:"text-black-900 bg-yellow-300 absolute -top-0.5 -right-1 p-1 text-xxs min-w-[1rem] rounded-full"},[_vm._v("\n      "+_vm._s(_vm.unreadCount)+"\n    ")]):_vm._e()],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }