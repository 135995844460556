import { render, staticRenderFns } from "./Label.vue?vue&type=template&id=4a782e81&scoped=true"
import script from "./Label.vue?vue&type=script&lang=js"
export * from "./Label.vue?vue&type=script&lang=js"
import style0 from "./Label.vue?vue&type=style&index=0&id=4a782e81&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4a782e81",
  null
  
)

export default component.exports