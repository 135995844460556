/* global axios */
import ApiClient from './ApiClient';

class WhatsappCampaignsAPI extends ApiClient {
  constructor() {
    super('whatsapp_campaigns', { accountScoped: true });
  }

  getStats(id) {
    return axios.get(`${this.url}/campaign_message_stats/${id}`);
  }

  stopCampaign(id) {
    return axios.get(`${this.url}/stop_campaign/${id}`);
  }
}

export default new WhatsappCampaignsAPI();
