var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"w-full"},[_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.processedString),expression:"processedString"}],staticClass:"template-input",attrs:{"rows":"4","readonly":""},domProps:{"value":(_vm.processedString)},on:{"input":function($event){if($event.target.composing)return;_vm.processedString=$event.target.value}}}),_vm._v(" "),(_vm.variables)?_c('div',{staticClass:"template__variables-container"},[_c('p',{staticClass:"variables-label"},[_vm._v("\n      "+_vm._s(_vm.$t('WHATSAPP_TEMPLATES.PARSER.VARIABLES_LABEL'))+"\n    ")]),_vm._v(" "),_vm._l((_vm.processedParams),function(variable,key){return _c('div',{key:key,staticClass:"template__variable-item"},[(
          _vm.template.name === 'catalouge_test_template' &&
          !_vm.processedParams[key + 1]
        )?_c('span',{staticClass:"variable-label"},[_vm._v("\n        "+_vm._s('Retailer Id')+"\n      ")]):_c('span',{staticClass:"variable-label"},[_vm._v("\n        "+_vm._s(key)+"\n      ")]),_vm._v(" "),_c('woot-input',{staticClass:"variable-input",attrs:{"type":"text","styles":{ marginBottom: 0 },"placeholder":_vm.template.name === 'catalouge_test_template' &&
          !_vm.processedParams[key + 1]
            ? 'Enter the Retailer Id'
            : ''},model:{value:(_vm.processedParams[key]),callback:function ($$v) {_vm.$set(_vm.processedParams, key, $$v)},expression:"processedParams[key]"}})],1)}),_vm._v(" "),(_vm.$v.$dirty && _vm.$v.$invalid)?_c('p',{staticClass:"error"},[_vm._v("\n      "+_vm._s(_vm.$t('WHATSAPP_TEMPLATES.PARSER.FORM_ERROR_MESSAGE'))+"\n    ")]):_vm._e()],2):_vm._e(),_vm._v(" "),_c('footer',[_c('woot-button',{attrs:{"variant":"smooth"},on:{"click":function($event){return _vm.$emit('resetTemplate')}}},[_vm._v("\n      "+_vm._s(_vm.$t('WHATSAPP_TEMPLATES.PARSER.GO_BACK_LABEL'))+"\n    ")]),_vm._v(" "),_c('woot-button',{attrs:{"type":"button"},on:{"click":_vm.sendMessage}},[_vm._v("\n      "+_vm._s(_vm.$t('WHATSAPP_TEMPLATES.PARSER.SEND_MESSAGE_LABEL'))+"\n    ")])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }